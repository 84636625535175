<template>
  <div class="logout">
    <a href="#" @click.prevent="isLogoutDialogVisible = true">
      <i class="el-icon-switch-button"></i>
      <span>
        Odjava
      </span>
    </a>
    <Dialog
      title="Odjava"
      custom-class="confirmation"
      :visible.sync="isLogoutDialogVisible">
      <div class="action">
        <Button
          type="primary"
          class="btn-light btn-block"
          native-type="submit"
          :loading="loading"
          @click="submitForm">Nastavi
        </Button>
      </div>
    </Dialog>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';
import {Authentication} from '@/mixins/Authentication';

// Element UI
import {Input, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AuthenticationLogoutIndex',
  mixins: [Common, Authentication],
  components: {
    Input,
    Button,
    Dialog
  },
  data() {
    return {
      isLogoutDialogVisible: false
    }
  },
  methods: {
    submitForm() {
      this.setLoadingComponent(true);
      this.Axios.post('authentication/logout').then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.isLogoutDialogVisible = false;
        this.$store.dispatch('setApplication');
        this.setAuthenticationRedirectToLoginAfterLogout();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';

.logout {
  a {
    display: block;
    text-align: center;
    color: white;

    span {
      display: block;
      font-size: 10px;
      text-transform: uppercase;
      margin-top: 4px;
    }
  }
}
</style>
