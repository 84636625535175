<template>
  <nav class="navigation" :class="{ 'show': isNavigationVisible}">
    <ul>
      <li>
        <router-link :to="{ name: 'AdministrationReportsRootIndex' }">
          Zapisnici
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdministrationInsurancesRootIndex' }">
          Osiguranja
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdministrationMarksRootIndex' }">
          Oznake
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdministrationClientsRootIndex' }">
          Stranke
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdministrationUsersRootIndex' }">
          Korisnici
        </router-link>
      </li>
    </ul>
    <div class="toggle">
      <Button class="btn-light" :icon="`${isNavigationVisible ? 'el-icon-close' : 'el-icon-menu'}`" circle @click="toggleNavigation"></Button>
    </div>
  </nav>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Button} from 'element-ui';

// Logic
export default {
  name: 'Navigation',
  mixins: [Common],
  components: {
    Button
  },
  data: function () {
    return {
      isNavigationVisible: false
    }
  },
  methods: {
    toggleNavigation: function () {
      this.isNavigationVisible === true ? this.isNavigationVisible = false : this.isNavigationVisible = true;
    }
  },
  watch:{
    $route(to, from) {
      this.isNavigationVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';

.navigation {
  width: 300px;
  background-color: $grey-100;
  padding: 12px 0;

  // Tablet
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 65px;
    bottom: 0;
    right: -280px;
    width: 280px;
    z-index: 1000;
    transition: $transition;

    &.show {
      right: 0;
      box-shadow: 0 0 20px 10px rgba(0,0,0,0.15);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 12px 16px;

      a {
        &.router-link-active {
          color: $primary;
        }
      }
    }
  }

  .toggle {
    display: none;

    // Tablet
    @include media-breakpoint-down(lg) {
      display: block;
      position: absolute;
      left: -60px;
      bottom: 24px;
    }
  }
}
</style>
