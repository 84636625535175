import Vue from 'vue';
import Application from './Application';
import router from '@/router';
import store from '@/store';
import {sync} from 'vuex-router-sync';

sync(store, router);

import '@/assets/scss/theme/index.css';

import Axios from 'axios';
import VueMoment from 'vue-moment';
import MomentTimezone from 'moment-timezone';
import VueCurrencyFilter from 'vue-currency-filter';
import VueHtml2pdf from 'vue-html2pdf';

Vue.use(VueMoment, {MomentTimezone});
Vue.use(VueCurrencyFilter, {thousandsSeparator: '.', fractionCount: 2, fractionSeparator: ','});
Vue.use(VueHtml2pdf);

Vue.axios = Axios;
Vue.axios.defaults.baseURL = 'https://api.app.tuca.hr/api/v1/';
// Vue.axios.defaults.baseURL = 'http://api.tuca.hr/api/v1/';
Vue.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
Vue.axios.defaults.headers.common['Authorization'] = store.state.accessToken;

Vue.prototype.Axios = Axios;

Vue.config.productionTip = true;
Vue.config.devtools = true;
Vue.config.debug = true;
Vue.config.silent = true;

router.beforeEach((to, from, next) => {
  store.dispatch('setLoading', true);

  if (store) {
    if (to.matched.some(data => data.meta.requiredAuthentication) === true && !!store.state.accessToken.length === false) {
      store.dispatch('setApplication');
      next({name: 'HomeIndex'});
    } else if (to.matched.some(data => data.meta.requiredAuthentication) === false && !!store.state.accessToken.length === true) {
      next({name: 'AdministrationReportsRootIndex'});
    } else {
      if (from.name === 'AdministrationReportsUpdateIndex') {
        store.dispatch('setReportDraft', null);
      }
      next();
    }
  } else {
    next({name: 'HomeIndex'});
  }
});

new Vue({
  router,
  store,
  render: h => h(Application)
}).$mount('#Application');