// Import Dependencies
import Vue from 'vue';

// Import packages
import Vuex from 'vuex';
import PersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";

// Assign
Vue.use(Vuex);
// const ls = new SecureLS({ isCompression: true });

export default new Vuex.Store({
    strict: false,
    plugins: [
        PersistedState({
            key: 'app.tuca.hr',
            // storage: {
            //   getItem: (key) => ls.get(key),
            //   setItem: (key, value) => ls.set(key, value),
            //   removeItem: (key) => ls.remove(key),
            // }
        })
    ],
    state: {
        accessToken: '',
        reportDraft: null,
        loading: false,
        version: ''
    },
    mutations: {
        setAccessToken(state, data) {
            state.accessToken = data;
        },
        setReportDraft(state, data) {
            state.reportDraft = data;
        },
        setLoading(state, data) {
            state.loading = data
        },
        setVersion(state, data) {
            state.version = data
        },
        setApplication(state) {
            state.accessToken = '';
            state.reportDraft = null;
            state.version = '0.0.1';
        }
    },
    actions: {
        setAccessToken({commit}, data) {
            commit('setAccessToken', data);
        },
        setReportDraft({commit}, data) {
            commit('setReportDraft', data);
        },
        setLoading({commit}, data) {
            commit('setLoading', data);
        },
        setVersion({commit}, data) {
            commit('setVersion', data);
        },
        setApplication({commit}) {
            commit('setApplication');
        }
    }
});
