export const Authentication = {
    methods: {
        setAuthenticationRedirectToLogin: function () {
            this.status === 'success' ? this.$router.push({name: 'HomeIndex'}) : null;
        },
        setAuthenticationRedirectToLoginAfterLogout: function () {
            this.$router.push({name: 'HomeIndex'});
        },
        setAuthenticationRedirect: function () {
            this.status === 'success' ? this.$router.push({name: 'AdministrationReportsRootIndex'}) : null;
        }
    }
};
