<template>
  <div id="application">
    <Loading v-if="$store.state.loading" />
    <TopBar v-if="$store.state.accessToken" />
    <div class="wrapper">
      <Navigation v-if="$store.state.accessToken" />
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Loading from '@/components/common/Loading';
import TopBar from '@/components/common/TopBar';
import Navigation from '@/components/common/Navigation';

// Mixins
import {Common} from '@/mixins/Common';

// Logic
export default {
  name: 'Index',
  mixins: [Common],
  components: {
    Loading,
    TopBar,
    Navigation
  },
  methods: {
    setVersion: function () {
      if (this.$store.state.version !== '0.0.1') {
        this.$store.dispatch('setApplication');
        this.$router.push({name: 'HomeIndex'});
      }
    }
  },
  created: function () {
    this.setVersion();
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';

.wrapper {
  display: flex;
  min-height: calc(100vh - 65px);

  .content {
    flex: 1;
    padding: 32px;

    // Tablet
    @include media-breakpoint-down(lg) {
      padding: 16px;
    }
  }
}
</style>