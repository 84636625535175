/* Import Dependencies */
import Vue from 'vue';
import Router from 'vue-router';

/* Assign */
Vue.use(Router);

/* Define Router */
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'HomeIndex',
      path: '/',
      meta: {
        requiredAuthentication: false
      },
      component: (resolve) => {
        require(['@/views/Home/Index'], resolve);
      }
    },
    {
      name: 'AuthenticationLoginIndex',
      path: '/authentication/login',
      meta: {
        requiredAuthentication: false
      },
      component: (resolve) => {
        require(['@/views/Authentication/Login/Index'], resolve);
      }
    },
    {
      name: 'AuthenticationPasswordRecoveryIndex',
      path: '/authentication/password-recovery',
      meta: {
        requiredAuthentication: false
      },
      component: (resolve) => {
        require(['@/views/Authentication/PasswordRecovery/Index'], resolve);
      }
    },
    {
      name: 'AuthenticationPasswordRecoveryVerificationIndex',
      path: '/authentication/password-recovery-verification/:code',
      meta: {
        requiredAuthentication: false
      },
      component: (resolve) => {
        require(['@/views/Authentication/PasswordRecoveryVerification/Index'], resolve);
      }
    },
    {
      name: 'AdministrationReportsRootIndex',
      path: '/administration/reports',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Reports/Root/Index'], resolve);
      }
    },
    {
      name: 'AdministrationReportsCreateIndex',
      path: '/administration/reports/create',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Reports/Create/Index'], resolve);
      }
    },
    {
      name: 'AdministrationReportsUpdateIndex',
      path: '/administration/reports/:id/update',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Reports/Update/Index'], resolve);
      }
    },
    {
      name: 'AdministrationReportsPreviewIndex',
      path: '/administration/reports/:id/preview',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Reports/Preview/Index'], resolve);
      }
    },
    {
      name: 'AdministrationInsurancesRootIndex',
      path: '/administration/insurances',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Insurances/Root/Index'], resolve);
      }
    },
    {
      name: 'AdministrationInsurancesDetailIndex',
      path: '/administration/insurances/:id/detail',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Insurances/Detail/Index'], resolve);
      }
    },
    {
      name: 'AdministrationMarksRootIndex',
      path: '/administration/marks',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Marks/Root/Index'], resolve);
      }
    },
    {
      name: 'AdministrationClientsRootIndex',
      path: '/administration/clients',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Clients/Root/Index'], resolve);
      }
    },
    {
      name: 'AdministrationUsersRootIndex',
      path: '/administration/users',
      meta: {
        requiredAuthentication: true
      },
      component: (resolve) => {
        require(['@/views/Administration/Users/Root/Index'], resolve);
      }
    },
    {
      path: '*',
      name: 'PageNotFoundIndex',
      component: (resolve) => {
        require(['@/views/PageNotFound/Index'], resolve);
      }
    }
  ]
});
