<template>
  <div class="top-bar">
    <div class="brand">
      <router-link :to="{ name: 'AdministrationReportsRootIndex' }">
        Tuča
      </router-link>
    </div>
    <ul>
      <li>
        <AuthenticationLogoutIndex/>
      </li>
    </ul>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Components
import AuthenticationLogoutIndex from '@/views/Authentication/Logout/Index';

// Logic
export default {
  name: 'TopBar',
  mixins: [Common],
  components: {
    AuthenticationLogoutIndex
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin: 0 auto;
  background-color: $primary;

  .brand {
    a {
      font-size: 28px;
      color: white;

      // Tablet
      @include media-breakpoint-down(lg) {
        font-size: 20px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-flex;
      margin-left: 16px;
    }
  }
}
</style>
