import {Notification} from 'element-ui';

export const Common = {
  data: function () {
    return {
      loading: false,
      message: '',
      status: ''
    }
  },
  methods: {
    initialize: function () {
      this.setLoading(false);
      window.scrollTo(0, 0);
    },
    setLoading: function (status) {
      if (status) {
        this.$store.dispatch('setLoading', status);
      } else {
        setTimeout(() => {
          this.$store.dispatch('setLoading', status);
        }, 1000);
      }
    },
    setLoadingComponent: function (status) {
      this.loading = status;
    },
    setNotification: function () {
      Notification({
        title: '',
        message: this.message,
        type: this.status,
        offset: 0
      });
    }
  },
  computed: {
    isDataPrepared: function () {
      return !this.loading && this.items.length;
    }
  }
};
